<template>
	<div class="bg-content" style="background-color: #C3C3C3;">
		
		<div v-for="item in historyList" :key="item.id">
			<div style="padding: 16px; background-color: #FFFFFF; margin-top: 1px;">
				<div class="row-content">
					<div class="his_info_content">
						<div class="row-content his_title_content">
							<div style="margin-right: 10px;">
								<label v-if="item.txType === 'TRANSFER'" class="his_type_lab">{{$t('history.transfer')}}</label>
								<label v-if="item.txType === 'EXCHANGE'" class="his_type_lab">{{$t('history.exchange')}}</label>
								<label v-if="item.txType === 'BUY'" class="his_type_lab">{{$t('history.buy')}}</label>
								<label v-if="item.txType === 'DEPOSIT'" class="his_type_lab">{{$t('history.deposit')}}</label>
								<label v-if="item.txType === 'WITHDRAW'" class="his_type_lab">{{$t('history.withdraw')}}</label>
								<label v-if="item.txType === 'REWARD'" class="his_type_lab">{{$t('history.reward')}}</label>
								<label v-if="item.txType === 'SYSTEM'" class="his_type_lab">{{$t('history.system')}}</label>
								<label v-if="item.txType === 'SHOP'" class="his_type_lab">{{$t('history.shop')}}</label>
								<label v-if="item.txType === 'SHOPPING'" class="his_type_lab">{{$t('history.shop')}}</label>
								<label v-if="item.txType === 'RELEASE'" class="his_type_lab">{{$t('history.release')}}</label>
								<label v-if="item.txType === 'UBUY'" class="his_type_lab">{{$t('history.buy')}}</label>
							</div>
							<label class="his_title_info">{{item.amount}}
								<span v-if="item.coinType === 'rpbt'">PBT</span>
								<span v-else>{{item.coinType}}</span>
							</label>
						</div>
						<div class="row-content his_sub_title_content">
							<label class="his_sub_title">{{$t('history.account')}}：</label>
							<label class="his_sub_info">{{item.username}}</label>
						</div>
						<div class="row-content his_sub_title_content">
							<label class="his_sub_title">{{$t('history.from')}}：</label>
							<label v-if="item.bak != ''" class="his_sub_info">{{item.bak}}</label>
							<label v-else class="his_sub_info">{{$t('history.system')}}</label>
						</div>
						<div class="row-content his_sub_title_content">
							<label class="his_sub_title">{{$t('history.time')}}：</label>
							<label class="his_sub_info">{{item.gmtCreate | timefilters }}</label>
						</div>
					</div>
					<div class="his_money_content">
						<label v-if="item.inOut === 'OUT'" class="his_money_text">-{{item.amount}}</label>
						<label v-if="item.inOut === 'IN'" class="his_money_text">+{{item.amount}}</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/head.css"
	import "@/styles/history.css"
	
	export default {
		name: 'HistoryList',
		props: {
			historyList: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		//注册局部过滤器
		filters: {
			timefilters(val) {
				if (val == null || val == "") {
					return "";
				} else {
					//val 为表格内取到的后台时间
					let d = new Date(val);
					let month =
					d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
					let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
					let hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
					let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
					let sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
					let times=d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;
					return times;
				}
			}
		},
	}
</script>

<style>
</style>
