<template>
	<div :style="bg">
		<Header :title="$t('assets.detail')"></Header>
	
		<div class="column-center-content main-content use" style="margin-top: 54px;">
			<mt-navbar v-model="selected" style="width: 100%; color: #009245;" color="#009245">
				<mt-tab-item id="1">{{$t('common.usdt')}}</mt-tab-item>
				<mt-tab-item id="8">{{$t('common.pbt')}}</mt-tab-item>
				<mt-tab-item id="2">{{$t('common.ambc')}}</mt-tab-item>
				<mt-tab-item id="3">{{$t('common.nasdaq')}}</mt-tab-item>
				<!-- <mt-tab-item id="4">{{$t('common.usdm')}}</mt-tab-item> -->
				<mt-tab-item id="5">{{$t('common.ambg')}}</mt-tab-item>
				<mt-tab-item id="6">{{$t('common.hk')}}</mt-tab-item>
				<mt-tab-item id="7">{{$t('common.wdt')}}</mt-tab-item>
				<mt-tab-item id="9">{{$t('common.bitMall')}}</mt-tab-item>
			</mt-navbar>

			<!-- tab-container -->
			<mt-tab-container v-model="selected">
				<mt-tab-container-item id="1">
					<HistoryList :historyList="historyList"></HistoryList>
				</mt-tab-container-item>
				<mt-tab-container-item id="8">
					<div class="head-business-content" style="margin-top: 1px; background-color: #FFFFFF;">
						<div class="head-business-info-content">
							<label class="head-red-lab">{{pbt}}</label>
							<label class="head-green-lab">{{$t('history.unReleased')}}</label>
						</div>
						<div style="width: 1px; height: 80%; background-color: #e1e1e1;"></div>
						<div class="head-business-info-content">
							<label class="head-red-lab">{{rpbt}}</label>
							<label class="head-green-lab">{{$t('history.released')}}</label>
						</div>
					</div>
					<HistoryList :historyList="historyList"></HistoryList>
				</mt-tab-container-item>
				<mt-tab-container-item id="2">
					<HistoryList :historyList="historyList"></HistoryList>
				</mt-tab-container-item>
				<mt-tab-container-item id="3">
					<HistoryList :historyList="historyList"></HistoryList>
				</mt-tab-container-item>
				<mt-tab-container-item id="4">
					<div class="head-business-content" style="margin-top: 1px; background-color: #FFFFFF;">
						<div class="head-business-info-content">
							<label class="head-red-lab">{{usdm}}</label>
							<label class="head-green-lab">{{$t('history.unReleased')}}</label>
						</div>
						<div style="width: 1px; height: 80%; background-color: #e1e1e1;"></div>
						<div class="head-business-info-content">
							<label class="head-red-lab">{{rusdm}}</label>
							<label class="head-green-lab">{{$t('history.released')}}</label>
						</div>
					</div>
					<HistoryList :historyList="historyList"></HistoryList>
				</mt-tab-container-item>
				<mt-tab-container-item id="5">
					<HistoryList :historyList="historyList"></HistoryList>
				</mt-tab-container-item>
				<mt-tab-container-item id="6">
					<HistoryList :historyList="historyList"></HistoryList>
				</mt-tab-container-item>
				<mt-tab-container-item id="7">
					<div class="head-business-content" style="margin-top: 1px; background-color: #FFFFFF;">
						<div class="head-business-info-content">
							<label class="head-red-lab">{{lwdt}}</label>
							<label class="head-green-lab">{{$t('history.unReleased')}}</label>
						</div>
						<div style="width: 1px; height: 80%; background-color: #e1e1e1;"></div>
						<div class="head-business-info-content">
							<label class="head-red-lab">{{wdt}}</label>
							<label class="head-green-lab">{{$t('history.released')}}</label>
						</div>
					</div>
					<HistoryList :historyList="historyList"></HistoryList>
				</mt-tab-container-item>
				<mt-tab-container-item id="9">
					<HistoryList :historyList="historyList"></HistoryList>
				</mt-tab-container-item>
			</mt-tab-container>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import { Indicator } from 'mint-ui'
	import HistoryList from './HistoryList.vue'
	import Header from '../../../components/NavHeader.vue'
	
	export default {
		name: 'History',
		components: {
			HistoryList,
			Header
		},
		data() { 
			return {
				bg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				selected: '',
				usdm: JSON.parse(localStorage.getItem('walletInfo')).musd ? JSON.parse(localStorage.getItem('walletInfo')).musd : 0,
				rusdm: JSON.parse(localStorage.getItem('walletInfo')).rusdm ? JSON.parse(localStorage.getItem('walletInfo')).rusdm : 0,
				pbt: JSON.parse(localStorage.getItem('walletInfo')).pbt ? JSON.parse(localStorage.getItem('walletInfo')).pbt : 0,
				rpbt: JSON.parse(localStorage.getItem('walletInfo')).rpbt ? JSON.parse(localStorage.getItem('walletInfo')).rpbt : 0,
				wdt: JSON.parse(localStorage.getItem('walletInfo')).wdt ? JSON.parse(localStorage.getItem('walletInfo')).wdt : 0,
				lwdt: JSON.parse(localStorage.getItem('walletInfo')).lwdt ? JSON.parse(localStorage.getItem('walletInfo')).lwdt : 0,
				historyList: [],
				usdtHisList: [],
				ambcHisList: [],
				nasdaqHisList: [],
				usdmHisList: [],
				ambgHisList: [],
				hkHisList: [],
				wdtHisList: [],
				pbtHisList: [],
				shopHisList: [],
				usdtCache: false,
				ambcCache: false,
				nasdaqCache: false,
				usdmCache: false,
				ambgCache: false,
				hkCache: false,
				wdtCache: false,
				pbtCache: false,
				shopCache: false
			}
		},
		mounted() {			
			if(this.$route.query.selected) {
				this.selected = this.$route.query.selected ? this.$route.query.selected : '1';
			}else{
				this.selected = '1';
			}
		},
		watch: {
			selected: function (val) {
				// 这里就可以通过 val 的值变更来确定
				this.historyList = [];
				switch(val){
					case '1':
						if(!this.usdtCache) {
							this.requestHistory('usdt', val);
						}else{
							this.historyList = this.usdtHisList;
						}
						break;
					case '2':
						if(!this.ambcCache) {
							this.requestHistory('ambc', val);
						}else{
							this.historyList = this.ambcHisList;
						}
						break;
					case '3':
						if(!this.nasdaqCache) {
							this.requestHistory('nasdaq', val);
						}else{
							this.historyList = this.nasdaqHisList;
						}
						break;
					case '4':
						if(!this.usdmCache) {
							this.requestHistory('usdm', val);
						}else{
							this.historyList = this.usdmHisList;
						}
						break;
					case '5':
						if(!this.ambgCache) {
							this.requestHistory('ambg', val);
						}else{
							this.historyList = this.ambgHisList;
						}
						break;
					case '6':
						if(!this.hkCache) {
							this.requestHistory('hk', val);
						}else{
							this.historyList = this.hkHisList;
						}
						break;
					case '7':
						if(!this.wdtCache) {
							this.requestHistory('wdt', val);
						}else{
							this.historyList = this.wdtHisList;
						}
						break;
					case '8':
						if(!this.pbtCache) {
							this.requestHistory('pbt', val);
						}else{
							this.historyList = this.pbtHisList;
						}
						break;
					case '9':
						if(!this.shopCache) {
							this.requestHistory('shop', val);
						}else{
							this.historyList = this.shopHisList;
						}
						break;
				}
			}
		},
		methods:{
			requestHistory(coinType, val) {
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				
				let params = {
					coinType: coinType
				}
				this.$request.history(
					params
				).then((data)=>{
					Indicator.close();
					this.$data.historyList = data;
					
					switch(val){
						case '1':
							this.usdtHisList = data;
							this.usdtCache = true;
							break;
						case '2':
							this.ambcCache = true;
							this.ambcHisList = data;
							break;
						case '3':
							this.nasdaqCache = true;
							this.nasdaqHisList = data;
							break;
						case '4':
							this.usdmCache = true;
							this.usdmHisList = data;
							break;
						case '5':
							this.ambgCache = true;
							this.ambgHisList = data;
							break;
						case '6':
							this.hkCache = true;
							this.hkHisList = data;
							break;
						case '7':
							this.wdtCache = true;
							this.wdtHisList = data;
							break;
						case '8':
							this.pbtCache = true;
							this.pbtHisList = data;
							break;
						case '9':
							this.shopCache = true;
							this.shopHisList = data;
							break;
					}					
				});
			}
		}
	}
</script>

<style>
	/* tab切换 */
	.use .mint-navbar {
		align-items: center;
		width: 240px;
		margin: 0 auto;
	}
	/* 设置切换后样式 */
	.use .mint-navbar .mint-tab-item.is-selected {
		border-bottom: 3px solid #009245;
		color:  #009245;
		margin: 0;
	}
	/* 设置切换前样式 */
	.use .mint-navbar .mint-tab-item {
		padding: 15px 0;
		color: #000000;
		border-bottom: 3px solid #FFFFFF;
	}
</style>
